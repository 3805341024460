import listOfIdeas from 'data/list-of-ideas.json';

const jsonFilesSlice = (set, get) => ({
  sliceName: 'jsonFiles',
  selectors: {
    listOfIdeas,
    goalToIdeaDict: listOfIdeas.reduce((soFar, idea) => {
      for (const goal of idea.goals) {
        // eslint-disable-next-line no-param-reassign
        soFar[goal.id] = idea.id;
      }
      return soFar;
    }, {}),
  },
});

export default jsonFilesSlice;

const countGoals = (ideasAndGoals = [], allIdeas = []) =>
  ideasAndGoals.reduce((count, ideasOrGoal) => {
    if (ideasOrGoal.type === 'idea') {
      return (
        count +
        (allIdeas.find((idea) => idea.id === ideasOrGoal.id)?.goals.length ?? 0)
      );
    }
    return count + 1;
  }, 0);

export default countGoals;
